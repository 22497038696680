.skills__container{
    grid-template-columns: repeat(2, 350px);
    column-gap: 3rem;
    justify-content: center;
}

.skills{
    padding: 0;
    padding-top: 5rem;
}

.skills__content{
    background-color: var(--container-color);
    border: 1px solid rgba(0,0,0,0.1);
    padding: 2rem 3rem;
    border-radius: 1.25rem;
}

.skills__title{
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: .5rem;
}

.skills__box{
    display: flex;
    justify-content: center;
    column-gap: 2.5rem;
}

.skills__group{
    display: grid;
    align-items: flex-start;
    row-gap: 1rem;
}

.skills__data{
    display: flex;
    column-gap: 0.5rem;
}

.skills .bx-badge-check{
    font-size: 1rem;
    color: var(--title-color);
}

.skills__name{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    line-height: 18px;
}

hr{
    margin-bottom: 1rem;

}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .skills__container{
        grid-template-columns: max-content;
        row-gap: 2rem;
    }
    
  }
  
  /* For medium devices */
  @media screen and (max-width: 576px) {
    .skills{
        padding-top: 0;
    }

    .skills__container{
        grid-template-columns: 1fr;
    }

    .skills__content{
        padding: .7rem;
    }

    .skills__box{
        justify-content: space-evenly;
    }
    .skills__container {
        padding-bottom: 4rem;
    }

    .section__subtitle {
        margin-bottom: 2rem;
    }
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {
    .skills__box{
        column-gap: 1.25rem;
    }

    .skills__name{
        font-size: var(--small-font-size);
    }

   
  }
 

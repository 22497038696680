.home__container{
  row-gap: 5rem;
}

.home{
  margin-top: 1rem;
}



.home__content {
  grid-template-columns: 116px repeat(2, 1fr);
  padding-top: 4rem;
  column-gap: 2rem;
  align-items: center;
}

.home__social {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1rem;
}

.home__social-icon {
  font-size: 1.25rem;
  color: var(--title-color);
}

.home__social-icon:hover {
  color: var(--title-color-dark);
}

.home__title {
  font-size: var(--big-font-size);
  margin-bottom: .1rem;
}
.home__hand {
  width: 27px;
  height: 27px;
  margin-left: 0.5rem;
}
.home__subtitle {
  position: relative;
  font-size: var(--h3-font-size);
  padding-right: 5.4rem;
  font-weight: var(--font-normal);
  margin-bottom: var(--mb-1);
}

/* .home__subtitle::before {
    content: '';
    position: absolute;
    width: 70px;
    height: 1px;
    background-color: var(--text-color);
    left: 0;
    top: 1rem;
} */

.home__description{
    max-width: 600px;
    margin-bottom: var(--mb-2);
}

.home__img {
  background: url(../../assets/profile.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 9px rgb(255, 255, 255 / 30%);
  order: 1;
  justify-self: center;
  width: 320px;
  height: 320px;
}

.home__scroll{
  margin-left: 9.25rem;
}

.wheel{
  animation: scroll 2s ease infinite;
}

@keyframes scroll{
  0%{
    transform: translateY(0);
  }
  30%{
    transform: translateY(3.75rem);
  }
}

.home__scroll-name{
  color: var(--title-color);
  font-weight: var(--font-medium);
  margin-right: var(--mb-0-25) ;
  margin-left: 2px;
}

.home__scroll-arrow{
  font-size: 1.25rem;
  color: var(--title-color);
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
  .home__content{
    grid-template-columns: 50px repeat(2, 1fr);
    column-gap: 1.25rem;
  }

  .home__hand{
    width:20px;
    height: 20px;
  }

  .home__subtitle{
    padding-left: 3rem;
    margin-bottom: var(--mb-1);
  }

  .home__subtitle::before{
    width: 42px;
    top: 0.8rem;
  }

  .home__description{
    max-width: initial;
    margin-bottom: var(--mb-2-5);
  }

  .home__img{
    width: 250px;
    height: 250px;
    box-shadow: inset 0 0 0 8px rgb(255, 255, 255 / 30%);
  }

  .home__scroll{
    margin-left: 7.5rem;
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .home__content{
    grid-template-columns: 0.5fr 3fr;
    padding-top: 0;
  } 
  .hello__div{
    justify-content: center;
    display: flex;
    
  }

  .home__img{
    order: initial;
    justify-self: initial;
  }
  .home__data{
    grid-column: 1/3;
  }

  .home__img{
    width: 220px;
    height: 220px;
  }

  .home__scroll{
    display: none;
  }

  .home__hand{
    width: 25px;
    height: 25px;
  }

  .home__title {
    font-size: 2rem;
    margin-bottom: .1rem;
  }

  /* .buttonhome{
    margin-left: 6rem;
  } */

  .hello__button{
    text-align: center;
    width: fit-content;

  }

}

/* For small devices */
@media screen and (max-width: 350px) {
.home__img{
  width: 180px;
  height: 180px;
}

.buttonhome{
  margin-left: 6rem;
}

.home__hand{
  width: 15px;
  height: 15px;
}

.home__title {
  font-size: 1.6rem;
  margin-bottom: .1rem;
}


}
